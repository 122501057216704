<template>
  <div class="single-resource fade-up">
    <el-row :gutter="30" style="position: relative;">
      <el-col :span="16">
        <el-card v-if="!isLoading" class="single-resource-content">
          <div v-if="isError">
            Топилмади!
          </div>
          <div v-else class="single-resource-inner">
            <div class="single-resource__details">
              <div class="single-resource__category">
                {{ singleResource.category.name_uz }}
              </div>
              <div class="single-resource__detail">
                {{
                  $date(singleResource.publish_at).format("HH:mm DD.MM.YYYY")
                }}
              </div>
              <div class="single-resource__detail">
                <i class="el-icon-view"></i>
                <span>{{ singleResource.views }}</span>
              </div>
            </div>
            <h1 class="single-resource__title">
              {{ singleResource.title_uz }}
            </h1>
            <div class="image-content">
              <img :src="$baseUrl + singleResource.thumbnail.url" alt="" />
              <span class="image-content__txt">
                Фото: {{ singleResource.thumbnail.alternativeText || "-" }}
              </span>
            </div>
            <div
              class="single-resource__content"
              v-html="singleResource.content_uz"
            ></div>
          </div>
        </el-card>
      </el-col>
      <el-col v-if="!isError" :span="8" style="position: sticky; top: 0">
        <el-card>
          <h3 class="actions-heading">Амаллар</h3>
          <div :style="{ display: 'flex' }">
            <el-button
              type="primary"
              icon="el-icon-edit"
              :style="{ flex: 1 }"
              @click="$router.push('/resources/edit/' + $route.params.id)"
            >
              Тахрирлаш
            </el-button>
            <el-button
              :disabled="isLoading"
              @click="deleteResource"
              type="danger"
              icon="el-icon-delete"
              :style="{ flex: 1 }"
            >
              Ўчириш
            </el-button>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data: () => ({
    isLoading: false,
    isError: false
  }),
  computed: {
    singleResource() {
      return this.$store.state.resourceModule.singleResource;
    }
  },
  created() {
    this.isLoading = true;
    const loading = this.$loading({
      lock: true
    });
    this.$store
      .dispatch("fetchSingleResource", this.$route.params.id)
      .catch(() => {
        this.isError = true;
      })
      .finally(() => {
        this.isLoading = false;
        loading.close();
      });
  },
  methods: {
    deleteResource() {
      this.$confirm("Ўчириш амалини тасдиқлайсизми?", "Диққат!", {
        confirmButtonText: "Ха",
        cancelButtonText: "Бекор қилиш",
        type: "warning"
      })
        .then(() => {
          this.isLoading = true;
          this.$store
            .dispatch("deleteResource", this.$route.params.id)
            .then(() => {
              this.$router.push("/resources");
              this.$message({
                type: "success",
                message: "Ўчирилди"
              });
            })
            .finally(() => (this.isLoading = false));
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Бекор қилинди"
          });
        });
    }
  }
};
</script>

<style lang="scss">
.single-resource {
  padding: 20px;

  &__details {
    display: flex;
    align-items: center;
    font-size: 0.75rem;
    line-height: 1.2;
    margin-bottom: 20px;
  }

  &__category {
    font-weight: 500;
    color: #aa7f26;
    background: rgba(231, 192, 111, 0.4);
    border-radius: 2px;
    padding: 4px 8px;
  }

  &__detail {
    margin-left: 24px;
    color: #999999;
    i {
      margin-right: 5px;
    }
  }

  &__title {
    line-height: 1.2;
    margin-bottom: 24px;
  }

  .actions-heading {
    margin-bottom: 20px;
  }

  .image-content {
    display: flex;
    flex-direction: column;
    line-height: 1.2;
    align-items: flex-start;
    margin-bottom: 20px;
    &__txt {
      color: #666666;
      font-size: 0.75rem;
      margin-top: 10px;
    }
  }

  p {
    font-size: 1.125rem;
    line-height: 1.4;
    font-weight: normal;
    margin-bottom: 19px;
  }

  img {
    max-width: 100%;
    height: auto;
  }
}
</style>
